@import 'node_modules/primeflex/primeflex.scss';

@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';

.logo{
    width: 100px;
}

.fondo{
    height: 100%;
    position: fixed;
}

.w-100{
    width: 100%;
    height: 100%;
}

.login{
    padding: 50px;
}

.nav-user{
    border-left: 3px solid #D9D9D9;
}


.text{
    padding: 16px;
    border-radius: 8px;
    background: rgba(249, 249, 249, 1);
}

.p-dialog-mask {
    background-color:rgb(217 216 216 / 43%) !important;
}

.document{
    border: 2px dashed #d5d5d5;
    border-radius: 20px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    color: #d5d5d5;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.document-ok{
    border: 2px dashed #d5d5d5;
    border-radius: 20px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.centered-radio {
    display: flex;
    align-items: center;
}
.centered-radio img {
    margin-right: 10px; /* Ajusta el margen según sea necesario */
  }